import { HStack, Img, Text } from "@chakra-ui/react";
import logo from "../../assets/logo.png";

export const Logo = ({ title }) => {
  return (
    <HStack>
      <Img h="45px" src={logo} />
      <Text> {title} </Text>
    </HStack>
  );
};
