import {
  Badge,
  Box,
  Button,
  Center,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";

import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

import { ClearAll } from "@material-ui/icons";
import { ImCheckmark } from "react-icons/im";
import { PropagateLoader } from "react-spinners";
import PulseLoaderComp from "../../components/queryMutatuinStates/PulseLoaderComp";
import { useAuth } from "../../hooks/auth-hook";

export const NotificationDrawer = (props) => {
  const {
    isOpen,
    setOpen,
    data,
    refetch,
    isSuccess,
    isLoading,
    unreadCount,
    search,
    handleSearch,
    handleClear,
  } = props;
  const { token, type, logout } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const queryClient = new useQueryClient();

  React.useEffect(() => {
    refetch();
    queryClient.invalidateQueries("notificationDetail");
  }, [isOpen, search]);

  React.useEffect(() => {
    refetch();
  }, [search]);
  const markAsRead = useMutation(
    `markAsRead`,
    async () =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}notifications/mark-as-read-all`,
        {},
        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notificationDetail");
        refetch();
      },
    }
  );

  const markAsReadSingle = useMutation(
    `markAsReadSingle`,
    async (id) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}notifications/${id}/mark-as-read`,
        {},
        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );
  React.useEffect(() => {
    return () => {
      handleClear();
    };
  }, []);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      placement="right"
      size="md"
      sx={{
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#046D76",
          outline: "1px solid slategrey",
          borderRadius: "10px",
        },
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {isSuccess ? (
          <Stack
            fontSize={"18px"}
            py="3%"
            spacing={"10px"}
            sx={{
              overflowY: "scroll",
            }}
          >
            <HStack justifyContent="space-between" margin={9} align={"center"}>
              <Heading fontSize={"xl"} textAlign={"center"} color={"#046D76"}>
                Notifications {isLoading}
              </Heading>
              <Badge colorScheme="green" borderRadius="full" px="2">
                {" "}
                {unreadCount}{" "}
              </Badge>
              <Button
                size="sm"
                iconLeft={<ImCheckmark />}
                color={unreadCount === 0 ? "#BA8B70" : "#046D76"}
                fontWeight={"bold"}
                onClick={() => {
                  markAsRead.mutate();
                  refetch();
                }}
                // disabled={unreadCount === 0}
                border={
                  unreadCount === 0 ? "1px solid #BA8B70" : "1px solid #046D76"
                }
              >
                {" "}
                Mark All As Read{" "}
              </Button>
            </HStack>
            <InputGroup size="md">
              <Input
                borderRadius="0"
                placeholder="Search"
                sx={{
                  border: "1px solid #000",
                  borderRadius: "10px",
                  fontSize: "md",
                  fontWeight: "bold",
                  mx: 5,
                  py: 3,
                  _focus: {
                    outline: "none",
                    border: "1px solid #000",
                  },
                }}
                value={search}
                onChange={handleSearch}
              />
              <InputRightAddon onClick={handleClear} children={<ClearAll />} />
            </InputGroup>
            {(isLoading ||
              markAsReadSingle?.isLoading ||
              markAsRead.isLoading) && (
              <Center my={"5"}>
                <PropagateLoader margin={"25px"} size={19} color="#046D76" />
              </Center>
            )}

            {data?.length > 0 ? (
              <Stack px="4%" fontSize={"16px "}>
                {data?.map((item) => (
                  <Box
                    key={item?.id}
                    p={4}
                    shadow="xl"
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow={"#385898 0px 0.5px 0px"}
                    background={item?.read_at ? "white" : "#E6F4F1"}
                    alignItems="center"
                    justifyContent="space-between"
                    // border={"1px solid #046D76"}
                    display="flex"
                  >
                    <Flex justifyContent="space-between" flexDirection="column">
                      <Text
                        color={item?.read_at ? "#046D76" : "#046D76"}
                        fontWeight={"bold"}
                        fontSize={"14px"}
                        // if text is too long  it will be on new line
                        overflowWrap="break-word"
                        wordWrap="break-word"
                        hyphens="auto"
                        maxW={"80%"}
                      >
                        {item?.data?.message}
                      </Text>
                      <Text
                        color={item?.read_at ? "#046D76" : "#BA8B70"}
                        fontSize={"10px"}
                      >
                        {moment(item?.created_at).format("DD MMM YYYY, h:mm a")}
                      </Text>
                    </Flex>
                    {item?.data?.action_by?.name?.length > 0 && (
                      <Flex direction={"column"} mx={3}>
                        <Text
                          color={item?.read_at ? "#046D76" : "#BA8B70"}
                          fontSize={"10px"}
                        >
                          By :
                        </Text>
                        <Badge
                          colorScheme="green"
                          borderRadius="full"
                          ml={-5}
                          px="2"
                        >
                          {item?.data?.action_by?.name}{" "}
                        </Badge>
                      </Flex>
                    )}
                    <Flex justifyContent="flex-end">
                      <Button
                        size={"sm"}
                        color={
                          item?.read_at ? "#BA8B70" : "rgba(4, 109, 118, 0.8)"
                        }
                        border={
                          item?.read_at
                            ? "1px solid #BA8B70"
                            : "1px solid #046D76"
                        }
                        background={item?.read_at ? "transparent" : "#E6F4F1"}
                        fontWeight={"bold"}
                        onClick={() => {
                          markAsReadSingle.mutate(item?.id);
                          refetch();
                        }}
                        disabled={item?.read_at !== null}
                      >
                        Mark As Read{" "}
                      </Button>
                    </Flex>
                  </Box>
                ))}
              </Stack>
            ) : (
              <Text textAlign={"center"} color={"#046D76"}>
                No Notification
              </Text>
            )}
          </Stack>
        ) : (
          <PulseLoaderComp h={"60vh"} />
        )}
      </DrawerContent>
    </Drawer>
  );
};
