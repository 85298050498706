import styled from "styled-components";

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 999;
  ${"" /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */}
  @media screen and (max-width: 992px) {
    height: 4rem;
  }
`;
