import {
  Box,
  Flex,
  Text,
  Heading,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Stack,
  HStack,
  Badge,
  Center,
} from "@chakra-ui/react";
import React from "react";

import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

import { useAuth } from "../../hooks/auth-hook";
import PulseLoaderComp from "../../components/queryMutatuinStates/PulseLoaderComp";
import { PropagateLoader } from "react-spinners";

export const OutofStockDrawer = (props) => {
  const {
    isOpen,
    onOpen,
    data,
    refetch,
    isSuccess,
    outStockCount,
    isLoading,
    onClose,
  } = props;

  const { token, type, logout } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const queryClient = new useQueryClient();

  const markAsRead = useMutation(
    `markAsRead`,
    async () =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}notifications/mark-as-read-all`,
        {},
        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notificationDetail");
        refetch();
      },
    }
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="md"
      sx={{
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#046D76",
          outline: "1px solid slategrey",
          borderRadius: "10px",
        },
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {isSuccess ? (
          <Stack
            fontSize={"18px"}
            py="3%"
            spacing={"10px"}
            sx={{
              overflowY: "scroll",
            }}
          >
            <HStack justifyContent="space-between" margin={9} align={"center"}>
              <Heading fontSize={"xl"} textAlign={"center"} color={"#D01C1C"}>
                Out of Stock Items
              </Heading>
              <Badge colorScheme="red" fontSize="xl" borderRadius="full">
                {outStockCount}
              </Badge>
            </HStack>
            {isLoading && (
              <Center mb={"5"}>
                <PropagateLoader margin={"25px"} size={19} color="#046D76" />
              </Center>
            )}

            {outStockCount > 0 ? (
              <Stack px="4%">
                {data?.map((item) => (
                  <Box
                    key={item?.id}
                    p={4}
                    shadow="xl"
                    borderWidth="1px"
                    borderRadius="md"
                    background={"#E6F4F1"}
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                    boxShadow="0 0 1px 1px #D01C1C"
                  >
                    <Flex
                      justifyContent="space-between"
                      width={"100%"}
                      alignItems="center"
                    >
                      <Box>
                        <Text
                          fontWeight={"bold"}
                          fontSize={"14px"}
                          overflowWrap="break-word"
                          color={"#046D76"}
                        >
                          {item?.items?.item_name}
                        </Text>
                      </Box>
                      <Badge
                        p={2}
                        backgroundColor={"#862400"}
                        borderRadius={"50%"}
                        color={"#fff"}
                      >
                        {item?.items?.item_number}
                      </Badge>
                      <Badge
                        p={2}
                        backgroundColor={"#862400"}
                        borderRadius={"50%"}
                        color={"#fff"}
                      >
                        {item?.items?.unit}
                      </Badge>
                    </Flex>
                  </Box>
                ))}
              </Stack>
            ) : (
              <Text textAlign={"center"} color={"#046D76"}>
                No Out of Stock Items
              </Text>
            )}
          </Stack>
        ) : (
          <PulseLoaderComp h={"60vh"} />
        )}
      </DrawerContent>
    </Drawer>
  );
};
