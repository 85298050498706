import { useAuth } from "./utilities/hooks/auth-hook";
import ScrollToTop from "./utilities/functions/ScrollToTop";
import { Center } from "@chakra-ui/react";
import { Suspense } from "react";
import NoAuthRoutes from "./routes/NoAuthRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import PulseLoaderComp from "./utilities/components/queryMutatuinStates/PulseLoaderComp";

function App() {
  //Hook
  const { type, token, Role, user, checked, login, logout } = useAuth();

  function RoutesComp() {
    if (token && !!Role) {
      return <AdminRoutes />;
    } else if (checked && !token) {
      return <NoAuthRoutes />;
    }
  }
  //  console log new date is smaller than 5:30 pm

  // console.log(new Date().getHours() < 17);
  // console.log(new Date().getHours() < 8);
  // console.log(new Date().getHours() < 19);

  console.log("sadas", new Date()?.toLocaleString()?.split(",")[1]);

  const downloadXML = () => {
    // Step 1: Create your XML content
    const xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
    <note>
      <to>Tove</to>
      <from>Jani</from>
      <heading>Reminder</heading>
      <body>Don't forget me this weekend!</body>
    </note>`;

    // Step 2: Convert the XML content to a Blob
    const blob = new Blob([xmlContent], { type: "application/xml" });

    // Step 3: Create a download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "note.xml"); // Set the file name

    // Step 4: Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <ScrollToTop />
      <Suspense
        fallback={
          <Center alignSelf={"center"} minH="500px">
            <PulseLoaderComp h={"90vh"} />
          </Center>
        }
      >
        {checked ? <RoutesComp /> : <PulseLoaderComp h={"90vh"} />}
        <button
          style={{ backgroundColor: "blue", width: 150, height: 150 }}
          onClick={downloadXML}
        >
          Download XML
        </button>
      </Suspense>
    </>
  );
}
console.log(
  "🚀 ~ App ~ REACT_APP_BACKEND_URL:",
  process.env.REACT_APP_BACKEND_URL
);

export default App;
