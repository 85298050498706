import {
  Badge,
  Box,
  Button,
  Center,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React from "react";

import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { DateRange } from "@material-ui/icons";
import moment from "moment";
import { PropagateLoader } from "react-spinners";
import PulseLoaderComp from "../../components/queryMutatuinStates/PulseLoaderComp";
import { useAuth } from "../../hooks/auth-hook";

export const OverTimeDrawer = (props) => {
  const { isOpen, onClose } = props;
  const { token, type, logout } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const queryClient = new useQueryClient();
  const [dateFilter, setDateFilter] = React.useState("");
  const toast = useToast();
  React.useEffect(() => {
    queryClient.invalidateQueries(`getOverTime, ${dateFilter}`);
    getOverTime.refetch();
  }, [JSON.stringify(dateFilter)]);

  React.useEffect(() => {
    return () => {
      queryClient.invalidateQueries(`getOverTime, ${dateFilter}`);
      setDateFilter("");
    };
  }, []);

  const getOverTime = useQuery(
    ["getOverTime", dateFilter],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}get/overtime?date=${dateFilter}`,
        {
          headers,
          // params: {
          //   date: dateFilter,
          // },
        }
      ),

    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );

  const handleFilter = (e) => {
    setDateFilter(e.target.value);
  };
  const handleClearDateFilter = () => {
    setDateFilter("");
  };
  const approveSingle = useMutation(
    `approveSingle`,
    async (id) =>
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}overtime/approve/${id}`,
        {},
        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getOverTime");
        getOverTime.refetch();
        toast({
          title: "Approved",
          description: "OverTime Approved",
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (err) => {
        toast({
          title: `${err?.response?.data?.message ?? "Error"}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="md"
      sx={{
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#046D76",
          outline: "1px solid slategrey",
          borderRadius: "10px",
        },
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {getOverTime.isSuccess ? (
          <Stack
            fontSize={"18px"}
            py="3%"
            spacing={"10px"}
            sx={{
              overflowY: "scroll",
            }}
          >
            <HStack justifyContent="space-between" margin={9} align={"center"}>
              <Heading fontSize={"xl"} textAlign={"center"} color={"#046D76"}>
                OverTime {JSON.stringify(dateFilter)}
              </Heading>
              <Badge colorScheme="green" borderRadius="full" px="2">
                {getOverTime?.data?.data?.length}{" "}
              </Badge>
            </HStack>
            <InputGroup size="md">
              <Input
                borderRadius="0"
                placeholder="Search"
                sx={{
                  border: "1px solid #000",
                  borderRadius: "10px",
                  fontSize: "md",
                  fontWeight: "bold",
                  mx: 5,
                  py: 3,
                  _focus: {
                    outline: "none",
                    border: "1px solid #000",
                  },
                }}
                type="date"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              />
              <InputRightAddon
                onClick={() => setDateFilter("")}
                children={<DateRange />}
              />
            </InputGroup>
            {(getOverTime.isFetching || approveSingle?.isLoading) && (
              <Center my={"5"}>
                <PropagateLoader margin={"25px"} size={19} color="#046D76" />
              </Center>
            )}

            {getOverTime?.data?.data?.length > 0 ? (
              <Stack px="4%" fontSize={"16px "}>
                {getOverTime?.data?.data?.map((item) => (
                  <Box
                    key={item?.id}
                    p={4}
                    shadow="xl"
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow={"#385898 0px 0.5px 0px"}
                    background={item?.read_at ? "white" : "#E6F4F1"}
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Flex justifyContent="space-between" flexDirection="column">
                      <Tooltip
                        label={item?.service?.service_type?.name}
                        aria-label="A tooltip"
                        sx={{
                          shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          padding: "10px",
                          borderRadius: "10px",
                          background: "#046D76",
                          color: "white",
                          fontSize: "14px",
                          fontWeight: "bold",
                          width: "100%",
                          textAlign: "center",
                          zIndex: "100",
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      >
                        <Text
                          color={item?.read_at ? "#046D76" : "#046D76"}
                          fontWeight={"bold"}
                          fontSize={"14px"}
                          overflowWrap="break-word"
                          wordWrap="break-word"
                          hyphens="auto"
                          maxW={"80%"}
                        >
                          {item?.service?.service_type?.name}{" "}
                        </Text>
                      </Tooltip>
                      <Text
                        color={"red.500"}
                        fontWeight={"extrabold"}
                        fontSize={"15px"}
                      >
                        End Time :{"  "}
                        {new Date(item?.end_time).toLocaleTimeString("en-US", {
                          hourCycle: "h23", // use 24-hour clock
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </Text>
                      <Text color={"#324B4E"} fontSize={"15px"}>
                        Length :{" "}
                        {(
                          Number(
                            moment(item?.end_time).diff(
                              moment().startOf("day").add(17, "hours")
                            )
                          ) / 3600000
                        ).toFixed(2)}{" "}
                        Hour (s)
                      </Text>
                    </Flex>
                    <Flex
                      direction={"column"}
                      mx={3}
                      align={"center"}
                      justify={"center"}
                    >
                      <Flex direction={"horizontal"} m={3}>
                        <Badge colorScheme="green" borderRadius="full">
                          <Text fontWeight={"light"} color={"#046D76"}>
                            Technician :
                          </Text>
                          {item?.technician?.name}{" "}
                        </Badge>
                      </Flex>
                      <Flex direction={"column"} mx={3}>
                        <Badge
                          colorScheme={item?.status == 0 ? "red" : "green"}
                          borderRadius="full"
                          // ml={-5}
                          p="2"
                        >
                          {item?.status == 0 ? "Pending" : "Approved"}{" "}
                        </Badge>
                      </Flex>
                    </Flex>
                    <Flex justifyContent="flex-end">
                      <Button
                        size={"sm"}
                        color={"rgba(4, 109, 118, 0.8)"}
                        border={
                          item?.read_at
                            ? "1px solid #BA8B70"
                            : "1px solid #046D76"
                        }
                        background={item?.read_at ? "transparent" : "#E6F4F1"}
                        fontWeight={"bold"}
                        onClick={() => {
                          approveSingle.mutate(item?.id);
                          getOverTime?.refetch();
                        }}
                        disabled={item?.status == 1}
                      >
                        Approve
                      </Button>
                    </Flex>
                  </Box>
                ))}
              </Stack>
            ) : (
              <Text textAlign={"center"} color={"#046D76"}>
                No OverTime
              </Text>
            )}
          </Stack>
        ) : (
          <PulseLoaderComp h={"60vh"} />
        )}
      </DrawerContent>
    </Drawer>
  );
};
