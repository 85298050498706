import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import axios from "axios";
import { useQuery, useQueryClient } from "react-query";

import { TimeIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/react";
import { BiSitemap } from "react-icons/bi";
import { FaStoreAltSlash } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrNotification } from "react-icons/gr";
import { MobileIcon } from "../../components/Global.elements";
import PasswordChangeModal from "../../components/PasswordChangeModal";
import { useAuth } from "../../hooks/auth-hook";
import BreadCrumb from "./BreadCrumb";
import { Nav } from "./Navigation.elements";
import { NotificationDrawer } from "./NotificationDrawer";
import { OutofStockDrawer } from "./OutofStockDrawer";
import { OverTimeDrawer } from "./OvetimeDrawer";
// import { OpenSparePart } from "./SparePartRequestion";

export default function NavBar({ btnRef, click, handleClick }) {
  const [isOpen, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState([]);
  const {
    isOpen: stockDrawertoggle,
    onOpen: stockDrawerOpen,

    onClose: stockDrawerClose,
  } = useDisclosure();

  const { token, logout, type, user } = useAuth();
  const navigate = useNavigate();
  const [passChange, setPassChange] = useState(false);
  const toast = useToast();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const myBasicAccount = useQuery(
    [`myBasicAccount`, token],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}profile`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: false,
      enabled: !!token,
      onSuccess: (data) => {
        toast.closeAll();
      },
      onError: (res) => {
        if (token && res?.response?.status === 401) {
          logout();
          navigate("/login");
          toast({
            title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (res?.message === "Network Error") {
          toast.closeAll();
          toast({
            title: (
              <HStack>
                <Text>Check your internet connection and </Text>
                <Button color={"#000"} onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </HStack>
            ),
            description: "Seems like there is issue with your connection",
            status: "error",
            duration: null,
          });
        }
      },
    }
  );
  console.log({ myBasicAccount });
  const storeManager = type === "SMAUTH";

  const onProfileExit = () => {
    setPassChange(false);
  };
  const technicalHeadRole = type === "THAUTH";

  const notificationData = useQuery(
    `notificationDetail`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}notifications`, {
        params: {
          search: search,
        },
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );

  React.useEffect(() => {
    if (notificationData?.isSuccess) {
      setData(notificationData?.data?.data);
    }
  }, [notificationData?.data?.data, notificationData?.isSuccess]);

  const countUnread = (data) => {
    let count = 0;
    data?.map((item) => {
      if (item.read_at === null) {
        count++;
      }
    });
    return count;
  };

  const stocksData = useQuery(
    "stocksDataForNotification",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stock`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const handleClear = () => {
    setSearch("");
  };
  const {
    isOpen: overtimeDrawer,
    onOpen: overtimeDrawerOpen,
    onClose: overtimeDrawerClose,
  } = useDisclosure();
  const {
    isOpen: requestionNotificationDrawer,
    onOpen: requestionNotificationDrawerOpen,
    onClose: requestionNotificationDrawerClose,
  } = useDisclosure();

  const getOverTime = useQuery(
    `getOverTime`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}get/overtime`, {
        headers,
        params: {
          date: dateFilter,
        },
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );

  const handleFilter = (e) => {
    setDateFilter(e.target.value);
  };
  const handleClearDateFilter = () => {
    setDateFilter("");
  };
  const queryClient = new useQueryClient();

  React.useEffect(() => {
    if (getOverTime?.isSuccess) {
      console.log(getOverTime?.data?.data);
    }
  }, [getOverTime?.data?.data, getOverTime?.isSuccess]);

  const memoizedOvertimeData = React.useMemo(() => {
    return getOverTime?.data?.data;
  }, [getOverTime?.data?.data, getOverTime?.isSuccess, dateFilter]);
  const foremanRole = type === "FAUTH";
  const leadCustomerRole = type === "LCSAUTH";
  // const customerServiceAgentRole = type === "CSAUTH";
  // const timeCoordinatorRole = type === "TCAUTH";

  // const storeManagerRole = type === "SMAUTH";
  // const laborManagerRole = type === "LMAUTH";
  // const financeManagerRole = type === "FAAUTH";
  // const adminRole = type === "ADMINAUTH";

  const getRequisitionNotification = useQuery(
    ["getRequisition"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}requisition-notifications`,
        {
          headers,
        }
      ),

    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );

  return (
    <Nav>
      <Flex
        maxW={{ base: "98%", lg: "100%" }}
        minW={{ base: "98%", lg: "87%" }}
      >
        {/* <ProfileChangeModal
        isOpen={isOpen}
        onClose={onProfileExit}
        userId={userId}
      /> */}
        <PasswordChangeModal isOpen={passChange} onClose={onProfileExit} />

        <HStack
          alignItems={"center"}
          style={{
            boxShadow: `rgba(0, 0, 0, 0.1) 0px 0.5px 0px`,
            position: "sticky",
            top: "0",
          }}
          w={"full"}
          py={3}
          bgColor="white"
          zIndex={500}
          borderRadius={7}
        >
          <Box pl={"3%"} flexGrow={1}>
            <BreadCrumb />
          </Box>
          <HStack spacing={4} align="center" pr={{ base: 6, lg: 6 }}>
            <Button
              display={foremanRole || leadCustomerRole ? "flex" : "none"}
              alignItems={"center"}
              // leftIcon={}
              // onClick={requestionNotificationDrawerOpen}
            >
              <BiSitemap
                style={{
                  marginRight: "5px",
                  fontSize: "20px",
                  color: "#385898",
                }}
                color={"#385898"}
              />
              <Badge colorScheme="red" p="2" borderRadius="full">
                {" "}
                {getRequisitionNotification?.data?.data}
              </Badge>
            </Button>
            {storeManager && (
              <Button
                leftIcon={
                  <FaStoreAltSlash
                    color={
                      stocksData?.data?.data?.filter(
                        (item) => item?.stock_alert === true
                      )?.length > 0
                        ? "red"
                        : "blue"
                    }
                    px="2"
                  />
                }
                onClick={stockDrawerOpen}
                sx={{
                  bg: "none",
                  border:
                    stocksData?.data?.data?.filter(
                      (item) => item?.stock_alert === true
                    )?.length > 0
                      ? "3px solid red "
                      : "blue",
                }}
              >
                <Badge
                  colorScheme="red"
                  fontSize="12px"
                  p="2"
                  borderRadius="full"
                >
                  {" "}
                  {
                    stocksData?.data?.data.filter(
                      (item) => item?.stock_alert === true
                    )?.length
                  }
                </Badge>
              </Button>
            )}
            {technicalHeadRole && (
              <Button leftIcon={<TimeIcon />} onClick={overtimeDrawerOpen}>
                {getOverTime?.data?.data?.length}
              </Button>
            )}
            <Button leftIcon={<GrNotification />} onClick={() => setOpen(true)}>
              <Badge colorScheme="green" borderRadius="full" px="2">
                {" "}
                {countUnread(data)}
              </Badge>
              {/* )} */}
            </Button>
            <Separet />
            <VStack>
              <Text fontSize={"14px"} fontWeight={"bold"} m={-1}>
                {user?.name}
              </Text>
              <Text fontSize={"12px"} fontWeight={"bold"}>
                {user?.username}
              </Text>
            </VStack>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <Box minW="32px">
                  <CgProfile />
                </Box>
              </MenuButton>
              <MenuList
                bg="#FFF"
                p={0}
                fontSize={"16px"}
                minW="100px"
                borderColor="#3F1052"
              >
                <MenuItem display={"none"}></MenuItem>

                <MenuItem
                  onClick={() => {
                    setPassChange(true);
                  }}
                  _active={{ bg: "#2FC61F", color: "#3F1052" }}
                  _hover={{ bg: "#385898", color: "#FFFFFF" }}
                >
                  <HStack>
                    <RiLockPasswordFill />
                    <Text>Change password</Text>
                  </HStack>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    logout();
                  }}
                  _active={{ bg: "#2FC61F", color: "#3F1052" }}
                  _hover={{ bg: "#385898", color: "#FFFFFF" }}
                >
                  <HStack>
                    <AiOutlineLogout />
                    <Center>Logout</Center>
                  </HStack>
                </MenuItem>
              </MenuList>
            </Menu>
            <MobileIcon onClick={handleClick} ref={btnRef}>
              <GiHamburgerMenu size={25} />
            </MobileIcon>
          </HStack>
        </HStack>
      </Flex>
      <NotificationDrawer
        isSuccess={notificationData.isSuccess}
        refetch={notificationData.refetch}
        isOpen={isOpen}
        setOpen={setOpen}
        data={data}
        unreadCount={countUnread(data)}
        isLoading={notificationData.isFetching || notificationData.isLoading}
        handleSearch={handleSearch}
        handleChange={handleChange}
        handleClear={handleClear}
        search={search}
      />
      {/* <OpenSparePart
        isOpen={requestionNotificationDrawer}
        onClose={requestionNotificationDrawerClose}
      /> */}
      <OutofStockDrawer
        isOpen={stockDrawertoggle}
        onOpen={stockDrawerOpen}
        onClose={stockDrawerClose}
        data={stocksData?.data?.data?.filter(
          (item) => item?.stock_alert === true
        )}
        outStockCount={
          stocksData?.data?.data.filter((item) => item?.stock_alert === true)
            ?.length
        }
        isLoading={stocksData?.isLoading}
        isSuccess={stocksData?.isSuccess}
      />
      <OverTimeDrawer
        isOpen={overtimeDrawer}
        onOpen={overtimeDrawerOpen}
        onClose={overtimeDrawerClose}
        data={memoizedOvertimeData}
        refetch={getOverTime.refetch}
        isLoading={getOverTime?.isFetching}
        isSuccess={getOverTime?.isSuccess}
        unreadCount={getOverTime?.data?.data?.length}
        handleFilter={handleFilter}
        handleClearDateFilter={handleClearDateFilter}
        dateFilter={dateFilter}
      />
    </Nav>
  );
}

const Separet = styled.div`
  height: 20px;
  width: 0.5px;
  border-radius: 200px;
  background-color: lightblue;
`;
